<template>
  <section v-if="show">
    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                class="d-flex justify-content-center"
              >
                <b-img
                  class="rounded-lg"
                  :src="`${$api_host}/storage/assets/${details.image}`"
                  width="100px"
                />
              </b-col>
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                :class="screen.width < 980 ? 'mb-2' : 'border-right'"
              >
                <div :class="screen.width < 980 ? 'text-center mt-2' : ''">
                  <span
                    style="font-size: 18px"
                    class="font-weight-bolder"
                  >
                    {{ details.name }}
                  </span>
                  <br>
                  <small class="text-muted"> Por {{ details.provider }} </small>
                </div>

                <b-form-spinbutton
                  :disabled="true"
                  class="mt-1"
                  :value="1"
                />
              </b-col>

              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
              >
                <h3
                  style="font-size: 16px"
                  class="font-weight-bolder text-center"
                >
                  {{ details.extraInfo1 }}
                </h3>

                <h3
                  style="font-size: 20px"
                  class="font-weight-bolder text-success text-center mt-2"
                >
                  {{ details.extraInfo2 }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
          <Description v-model="option_selected.description" />
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <label class="section-label mb-1">Detalhes da Compra</label>
            <hr>

            <!-- Detalhes da Compra -->
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <span class="font-weight-bolder"> Detalhes do Preço </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="3"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Nível </span>
                </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="9"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ discountLevel.name }}</span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Desconto </span>
                </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ discountLevel.discount }}%</span>
              </b-col>

              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Valor </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ price }}</span>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span class="font-weight-bolder"> Total </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ total }}</span>
              </b-col>
            </b-row>

            <div>
              <b-button
                v-if="!loading"
                variant="relief-primary"
                block
                @click="purchase()"
              >
                Finalizar
              </b-button>
              <b-button
                v-else
                variant="relief-primary"
                disabled
                block
              >
                <b-spinner small />
              </b-button>
              <div class="cho-container" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BImg,
  BSpinner,
  BFormSpinbutton
} from 'bootstrap-vue'
import SMSCheckoutMixin from '../mixins/SMSCheckoutMixin'
import currencyPrecision from '@/libs/currencyPrecision'
import Description from '../components/Description.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    BSpinner,
    BFormSpinbutton,
    Description
  },
  mixins: [SMSCheckoutMixin],
  computed: {
    screen () {
      return {
        width: window.screen.width,
        height: window.screen.height
      }
    },
    price () {
      return currencyPrecision(this.details.price, 2, 4)
    },
    total () {
      return currencyPrecision(this.calcDiscount(this.details.price), 2, 4)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
