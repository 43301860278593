<template>
  <section>
    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <Service
            v-model="selected"
            :options="options"
          />

          <!-- Select Service -->
          <Description
            v-if="selected !== null"
            :value="selected.description"
          />

          <Order
            v-if="selected !== null"
            :option="selected"
            @update="updateOrder($event)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-card :class="screen.width > 980 ? 'position-fixed' : ''">
            <label class="section-label mb-1">Detalhes da Compra</label>
            <hr>

            <!-- Detalhes da Compra -->
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <span class="font-weight-bolder"> Detalhes do Preço </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="3"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Nível </span>
                </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="9"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ discountLevel.name }}</span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Desconto </span>
                </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ discountLevel.discount }}%</span>
              </b-col>

              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Quantidade </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ quantity }}</span>
              </b-col>

              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Valor </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ cost | currency }}</span>
              </b-col>

              <b-col
                v-if="selected !== null"
                cols="4"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Seguidores </span>
                </span>
              </b-col>

              <b-col
                v-if="selected !== null"
                cols="8"
                class="d-flex justify-content-end"
              >
                <span
                  class="mr-1"
                >{{ (calcDiscount(selected.value * 1000)) | normalCurrency }} a cada 1000</span>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span class="font-weight-bolder"> Total </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ total }}</span>
              </b-col>
            </b-row>

            <div>
              <b-button
                v-if="!loading"
                :disabled="!calculated"
                variant="relief-primary"
                block
                @click="purchase()"
              >
                Finalizar
              </b-button>
              <b-button
                v-else
                variant="relief-primary"
                disabled
                block
              >
                <b-spinner small />
              </b-button>
              <div class="cho-container" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BImg, BSpinner
} from 'bootstrap-vue'
import currency from 'currency.js'
import Service from './Service.vue'
import Description from './Description.vue'
import Order from './Order.vue'
import SNActionsMixin from '../../mixins/SNActionsCheckoutMixin'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    BSpinner,
    Service,
    Description,
    Order
  },
  filters: {
    currency (val) {
      return currency(val, {
        separator: '.',
        decimal: ',',
        symbol: 'R$ ',
        precision: 6
      }).format()
    },
    normalCurrency (val) {
      return currency(val, {
        separator: '.',
        decimal: ',',
        symbol: 'R$ ',
        precision: 2
      }).format()
    }
  },
  mixins: [SNActionsMixin],
  computed: {
    screen () {
      return {
        width: window.screen.width,
        height: window.screen.height
      }
    },
    total () {
      return currencyPrecision(this.cost, 2, 6)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
