<template>
  <b-card v-if="text !== null && text !== ''">
    <b-row>
      <b-col cols="12">
        <b-card-text
          class="mt-1 mb-1 d-flex justify-content-center font-weight-bolder"
          style="font-size: 18px"
        >
          Descrição
        </b-card-text>
      </b-col>

      <b-col cols="12">
        <b-card
          class="border"
          no-body
        >
          <pre
            style="overflow: hidden; white-space: pre-wrap; font-size: 14px"
            v-html="text"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText
  },
  model: {
    prop: 'text',
    event: 'update:text'
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
