<template>
  <b-card v-if="value !== null && value !== ''">
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-end"
      >
        <b-button
          v-if="hide === false"
          size="sm"
          variant="relief-primary"
          @click.stop="hide = true"
        >
          Ocultar descrição
        </b-button>

        <b-button
          v-else
          size="sm"
          variant="relief-primary"
          @click.stop="hide = false"
        >
          Mostrar descrição
        </b-button>
      </b-col>

      <b-col
        v-if="!hide"
        cols="12"
      >
        <b-card-text
          class="mt-1 mb-1 d-flex justify-content-center font-weight-bolder"
          style="font-size: 18px"
        >
          Descrição
        </b-card-text>
      </b-col>

      <b-col
        v-if="!hide"
        cols="12"
      >
        <b-card
          class="border"
          no-body
        >
          <pre
            style="overflow: hidden; white-space: pre-wrap; font-size: 14px"
            v-html="value"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    hide: false
  })
}
</script>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
