<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-button
            v-if="type === 'unique'"
            class="float-right"
            size="sm"
            variant="relief-primary"
            @click.stop="type = 'bulk'"
          >
            Fazer Pedido em massa
          </b-button>
          <b-button
            v-if="type === 'bulk'"
            class="float-right"
            size="sm"
            variant="relief-primary"
            @click.stop="type = 'unique'"
          >
            Fazer Pedido Individual
          </b-button>
        </b-col>
        <b-col>
          <b-form-group
            v-if="type === 'unique'"
            label-size="lg"
            label="Coloque o seu link"
            label-for="service_id"
          >
            <b-form-input
              v-model="links"
              placeholder=""
              no-auto-shrink
            />
          </b-form-group>
          <b-form-group
            v-else
            label-size="lg"
            label="Coloque os seus links (Você pode definir mais de um link por linha)"
            label-for="service_id"
          >
            <b-form-textarea
              v-model="links"
              placeholder=""
              rows="1"
              max-rows="8"
              no-auto-shrink
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-size="lg"
            label="Quantidade"
            label-for="service_id"
          >
            <Quantity
              v-model="quantity"
              :disabled="option === null || links.length === 0"
              :min="min"
              :max="max"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-if="calculated === false"
            variant="relief-primary"
            block
            :disabled="
              option === null || links.length === 0 || !checkMinMax
            "
            @click.stop="calculate()"
          >
            Calcular
          </b-button>

          <b-alert
            v-if="calculated"
            variant="success"
            show
          >
            <h4 class="alert-heading text-center">
              Valor total do pedido: R$ {{ cost | currency }}
            </h4>
          </b-alert>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <b-alert
            v-if="calculated"
            variant="dark"
            show
          >
            <h4 class="alert-heading text-center">
              Essa ação não pode ser cancelada.
            </h4>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BButton,
  BAlert
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import currencyPrecision from '@/libs/currencyPrecision'
import Quantity from './Quantity.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    Quantity,
    BButton,
    BAlert
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 6)
    }
  },
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    type: 'unique',
    links: '',
    min: 1,
    max: 1,
    quantity: 1,
    cost: 0,
    snActions: [],
    calculated: false,
    order: ''
  }),
  computed: {
    ...mapState('discountLevel', ['level']),
    hasBenefit () {
      const benefits = Object.values(this.level.benefit_applied)
      return benefits.includes(2)
    },
    discountLevel () {
      return this.level
    },
    checkMinMax () {
      return this.quantity >= this.min && this.quantity <= this.max
    }
  },
  watch: {
    calculated (val) {
      if (val) return

      this.$emit('update', {
        id: this.option.id,
        quantity: this.quantity,
        cost: this.cost,
        order: this.order,
        calculated: false
      })
    },
    quantity () { this.calculated = false },
    links () { this.calculated = false },
    option () {
      this.updateMinMax()
      this.calculated = false
      this.order = ''
      this.cost = 0
    }
  },
  async mounted () {
    this.updateMinMax()
  },
  methods: {
    updateMinMax () {
      this.min = this.option.min
      this.max = this.option.max

      this.quantity = parseInt(this.min)
    },
    calcDiscount (value) {
      if (this.hasBenefit) {
        return parseFloat(value - (value / 100) * this.level.discount)
      }

      return value
    },
    calculate () {
      const links = this.links.split('\n')
      let custom = ''
      const linksQuantity = this.links
        .split('\n')
        .filter(item => item.length > 0).length

      for (const link of links) {
        if (link.length === 0) continue
        custom += `${this.option.id}|${link}|${this.quantity}\n`
      }

      const cost = linksQuantity * this.quantity * this.option.value
      this.cost = this.calcDiscount(cost)
      this.order = custom
      this.calculated = true

      this.$emit('update', {
        id: this.option.id,
        quantity: this.quantity,
        cost: this.cost,
        order: this.order,
        calculated: this.calculated
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
