import { mapState } from 'vuex'
import SwalMessages from '../utils/SwalMessages'

export default {
  data: () => ({
    loading: false,
    show: true,
    formData: {
      quantity: 1
    }
  }),
  computed: {
    ...mapState('buyServices', [
      'option_selected'
    ]),
    ...mapState('discountLevel', ['level']),
    maxQuantity () {
      return this.option_selected.stock
    },
    hasBenefit () {
      const benefits = Object.values(this.level.benefit_applied)
      return benefits.includes(3)
    },
    discountLevel () {
      return this.level
    }
  },
  watch: {
    'formData.quantity' () {
      if (this.formData.quantity < 0) {
        this.formData.quantity = 1
      }

      if (this.formData.quantity > this.maxQuantity) {
        this.formData.quantity = parseInt(this.maxQuantity)
      }

      if (this.formData.quantity < 1) {
        this.formData.quantity = parseInt(1)
      }

      this.formData.quantity = parseInt(this.formData.quantity)

      if (isNaN(this.formData.quantity)) {
        this.formData.quantity = 1
      }
    }
  },
  methods: {
    calcDiscount (value) {
      if (this.hasBenefit) {
        return parseFloat(value - (value / 100) * this.level.discount)
      }

      return value
    },
    async purchase () {
      this.loading = true
      const url = '/user/services/accounts/purchase'
      const params = {
        service_option_id: this.option_selected.id,
        quantity: this.formData.quantity
      }

      await this.$http.post(url, params)
        .then(res => {
          if (res.data.message === 'successfully created.' && res.status === 201) this.success()
          if (res.data.message === 'no balance.') this.noBalance()
          if (res.data.message === 'quantity is invalid.' && res.response.status === 500) this.quantityIsInvalid(res.response.data.available)
        }).finally(() => {
          this.loading = false
        })
    },
    success () {
      this.$swal(SwalMessages.swal_sn_accounts_success).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Minhas Contas' })
        : null))
    },
    quantityIsInvalid (number) {
      this.$swal(SwalMessages.swal_sn_accounts_quantity_is_invalid(number))
    },
    noBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    }
  }
}
