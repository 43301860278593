<template>
  <section v-if="Object.keys(option_selected).length > 0">
    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                class="d-flex justify-content-center"
              >
                <b-img
                  class="rounded-lg"
                  :src="`${$api_host}/storage/assets/${option_selected.image}`"
                  width="125px"
                />
              </b-col>
              <b-col
                cols="12"
                lg="8"
                md="12"
                sm="12"
                :class="screen.width < 980 ? 'mb-2' : ''"
              >
                <div :class="screen.width < 980 ? 'text-center mt-2' : ''">
                  <span
                    style="font-size: 18px"
                    class="font-weight-bolder"
                  >
                    {{ option_selected.name }}
                  </span>
                  <br>
                  <small class="text-muted"> Por ContasInfinity </small>
                </div>

                <b-input-group class="mt-2">
                  <b-input-group-prepend>
                    <b-button
                      variant="relief-primary"
                      @click.stop="formData.quantity--"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="formData.quantity"
                    type="number"
                    placeholder="Quantidade"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="relief-primary"
                      @click.stop="formData.quantity++"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card>
          <Description v-model="option_selected.description" />
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-card :class="screen.width > 980 ? 'position-fixed' : ''">
            <label class="section-label mb-1">Detalhes da Compra</label>
            <hr>

            <!-- Detalhes da Compra -->
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <span class="font-weight-bolder"> Detalhes do Preço </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="3"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Nível </span>
                </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="9"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ discountLevel.name }}</span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Desconto </span>
                </span>
              </b-col>

              <b-col
                v-if="hasBenefit"
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ discountLevel.discount }}%</span>
              </b-col>

              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Valor </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ price }}</span>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span class="font-weight-bolder"> Total </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ total }}</span>
              </b-col>
            </b-row>

            <div>
              <b-button
                v-if="!loading"
                variant="relief-primary"
                block
                @click="purchase()"
              >
                Finalizar
              </b-button>
              <b-button
                v-else
                variant="relief-primary"
                disabled
                block
              >
                <b-spinner small />
              </b-button>
              <div class="cho-container" />
            </div>
          </b-card>
        </b-col>
      </b-row>

    </b-col>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BImg,
  BSpinner,
  BFormInput,
  BFormSpinbutton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend
} from 'bootstrap-vue'
import SNAccountsMixin from '../../mixins/SNAccountsMixin'
import currencyPrecision from '@/libs/currencyPrecision'
import Description from '../../components/Description.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    BSpinner,
    BFormInput,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    Description
  },
  mixins: [SNAccountsMixin],
  computed: {
    screen () {
      return {
        width: window.screen.width,
        height: window.screen.height
      }
    },
    price () {
      const value = this.option_selected.value * this.formData.quantity
      return currencyPrecision(value, 2, 4)
    },
    total () {
      const value = this.option_selected.value * this.formData.quantity
      return currencyPrecision(this.calcDiscount(value), 2, 4)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
