<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <tab-content
      title="Serviços"
      icon="feather icon-shopping-cart"
    >
      <services @next-step="formWizardNextStep" />
    </tab-content>

    <tab-content
      v-if="show"
      title="Opções"
      icon="feather icon-home"
    >
      <options @next-step="formWizardNextStep" />
    </tab-content>

    <!-- Adiciona um novo item caso seja SMS -->
    <tab-content
      v-if="show && isSMS"
      title="Rede Social"
      icon="feather icon-instagram"
    >
      <social-network @next-step="formWizardNextStep" />
    </tab-content>
    <!-- -->

    <tab-content
      v-if="show"
      title="Pagamento"
      icon="feather icon-credit-card"
    >
      <sms-checkout v-if="isSMS" />
      <sn-actions-checkout v-else-if="isSNActions" />
      <pack-of-photos v-else-if="isPackOfPhotos" />
      <sn-accounts-checkout v-else />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import Services from './Services.vue'
import Options from './Options.vue'
import SocialNetwork from './SocialNetworks.vue'
import SmsCheckout from './Checkouts/SMSCheckout.vue'
import SnActionsCheckout from './Checkouts/SNActions/Index.vue'
import SnAccountsCheckout from './Checkouts/SNAccounts/Index.vue'
import PackOfPhotos from './Checkouts/PackOfPhotos/Index.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    Services,
    Options,
    SocialNetwork,
    SmsCheckout,
    SnActionsCheckout,
    SnAccountsCheckout,
    PackOfPhotos
  },
  data: () => ({
    show: true
  }),
  computed: {
    ...mapGetters('buyServices', ['isSMS', 'isSNActions', 'isPackOfPhotos'])
  },
  watch: {
    isSMS () {
      this.refreshFormWizard()
    }
  },
  mounted () {
    this.getServices()
  },
  methods: {
    ...mapActions('buyServices', ['getServices']),
    refreshFormWizard () {
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 100)

      setTimeout(() => {
        this.formWizardNextStep()
      }, 200)
    }
  },
  setup () {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    return {
      refFormWizard,
      formWizardNextStep
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
