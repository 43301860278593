<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <span
          class="font-weight-bolder mb-1"
          style="font-size: 18px"
        >
          Serviços
        </span>
      </b-col>
      <b-col cols="12">
        <b-form-select
          :value="selected"
          :state="selected === null ? false : true"
          :options="options"
          @change="(val) => $emit('update:selected', val)"
        >
          <template #first>
            <b-form-select-option
              :value="null"
            >Por favor, selecione um serviço!</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BFormSelectOption
  },
  model: {
    prop: 'selected',
    event: 'update:selected'
  },
  props: {
    options: {
      default: [],
      type: Array
    },
    selected: {
      default: null
    }
  },
  data: () => ({
    selected: null
  }),
  watch: {
    selected () {
      this.$emit('change', this.selected)
    }
  }
}
</script>
