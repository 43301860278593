<template>
  <b-row>
    <b-col
      offset-sm-2
      sm="10"
      md="12"
      offset-lg="2"
      lg="10"
      class="mx-auto"
    >
      <b-row>
        <b-col
          v-for="(item, index) in option_selected.social_networks"
          :key="index"
          cols="12"
          md="4"
          sm="12"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-center"
              >
                <img
                  class="rounded-lg"
                  :src="`${$api_host}/storage/assets/${item.image}`"
                  width="50px"
                >
              </b-col>

              <b-col
                cols="12"
                class="d-flex justify-content-center"
              >
                <span class="font-weight-bolder mt-1 text-center">
                  {{ item.name }}
                </span>
              </b-col>

              <b-col
                class="mt-1 d-flex justify-content-center"
                cols="12"
              >
                <b-badge variant="primary">
                  <feather-icon
                    icon="DollarSignIcon"
                    class="mr-25"
                  />
                  <span>{{ item.value | currency }} a unidade</span>
                </b-badge>
              </b-col>

              <b-col cols="12">
                <b-button
                  block
                  class="mt-2"
                  variant="relief-primary"
                  :disabled="!!item.deactivated"
                  @click.stop="select(item)"
                >
                  Selecionar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BButton, BBadge
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BButton,
    BBadge
  },
  filters: {
    currency (val) {
      return val.toFixed(2).replaceAll('.', ',')
    }
  },
  computed: {
    ...mapState('buyServices', ['option_selected'])
  },
  methods: {
    ...mapActions('buyServices', ['setSocialNetwork']),
    select (item) {
      this.setSocialNetwork(item)
      this.$emit('next-step')
    }
  }
}
</script>
