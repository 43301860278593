<template>
  <b-row>
    <b-col
      cols="12"
      class="d-flex justify-content-center"
    >
      <b-input-group>
        <b-form-input
          :value="item"
          :disabled="disabled"
          type="number"
          placeholder="Quantidade"
          @change="(val) => $emit('update:item', parseInt(val))"
        />
      </b-input-group>
    </b-col>

    <b-col
      v-if="!checkMinMax"
      cols="12"
      class="mt-1"
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading text-center">
          O valor mínimo é {{ min }} e o máximo é {{ max }}
        </h4>
      </b-alert>
    </b-col>

    <b-col
      cols="12"
      class="mt-1"
    >
      <b-progress
        height="20px"
        max="100"
      >
        <b-progress-bar
          variant="danger"
          value="50"
          :label="`MIN: ${min}`"
        />
        <b-progress-bar
          variant="success"
          value="50"
          :label="`MAX: ${max}`"
        />
      </b-progress>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BProgressBar,
  BProgress,
  BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BProgressBar,
    BProgress,
    BAlert
  },
  model: {
    prop: 'item',
    event: 'update:item'
  },
  props: {
    item: {
      type: Number,
      default: 0,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkMinMax () {
      return this.item >= this.min && this.item <= this.max
    }
  }
}
</script>
