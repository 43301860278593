import { mapState } from 'vuex'
import SwalMessages from '../utils/SwalMessages'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  data: () => ({
    loading: false,
    calculated: false,
    options: [],
    selected: null,
    quantity: 1,
    cost: 0,
    order: ''
  }),
  watch: {
    'option_selected.sn_actions' () {
      this.selected = null
      this.options = this.snActions
    }
  },
  computed: {
    ...mapState('buyServices', [
      'option_selected'
    ]),
    ...mapState('discountLevel', ['level']),
    snActions () {
      return this.option_selected.sn_actions.map(item => {
        return {
          value: item,
          html: `${item.name} [ID: ${item.id}] - ${currencyPrecision(item.value * 1000, 2, 2)} por 1000`
        }
      })
    },
    hasBenefit () {
      const benefits = Object.values(this.level.benefit_applied)
      return benefits.includes(2)
    },
    discountLevel () {
      return this.level
    }
  },
  mounted () {
    this.selected = null
    this.options = this.snActions
  },
  methods: {
    updateOrder (data) {
      this.quantity = data.quantity
      this.cost = data.cost
      this.order = data.order
      this.calculated = data.calculated
    },
    calcDiscount (value) {
      if (this.hasBenefit) {
        return parseFloat(value - (value / 100) * this.level.discount)
      }

      return value
    },
    async purchase () {
      this.loading = true

      const url = '/user/services/sn_actions/purchase/bulk'
      const params = {
        order_bulk: this.order
      }

      await this.$http
        .post(url, params)
        .then(res => {
          if (
            (res.data.message === 'your order has been added to the queue!'
              || res.data.message === 'successfully created.')
            && res.status === 201
          ) { this.success() }
          if (res.data.message === 'no balance.') this.noBalance()
          if (res.data.message === 'Something went wrong.') this.simpleError()
        })
        .finally(() => {
          this.loading = false
        })
    },
    success () {
      this.$swal(SwalMessages.order_bulk_success)
        .then(buttons => (buttons.isConfirmed
          ? this.$router
            .push({ name: 'Meus Seguidores' })
            .finally(() => {
              window.location.reload()
            })
          : null))
    },
    noBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    },
    linkDuplicated () {
      this.$swal(SwalMessages.order_unique_link_duplicated)
    },
    simpleError () {
      this.$swal(SwalMessages.swal_simple_error)
    }
  }
}
