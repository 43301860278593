import { mapState, mapGetters, mapActions } from 'vuex'
import SwalMessages from '../utils/SwalMessages'

export default {
  data: () => ({
    loading: false,
    show: false,
    details: {
      name: '',
      image: '',
      price: '',
      provider: '',
      description: '',
      extraInfo1: '',
      extraInfo2: ''
    }
  }),
  watch: {
    socialNetwork_selected () {
      this.resolveDetails()
      this.show = true
    }
  },
  computed: {
    ...mapState('buyServices', [
      'option_selected',
      'socialNetwork_selected'
    ]),
    ...mapState('discountLevel', ['level']),
    ...mapGetters('buyServices', [
      'isSMS'
    ]),
    hasBenefit () {
      const benefits = Object.values(this.level.benefit_applied)
      return benefits.includes(1)
    },
    discountLevel () {
      return this.level
    }
  },
  methods: {
    ...mapActions('buyServices', [
      'setSocialNetwork'
    ]),
    calcDiscount (value) {
      if (this.hasBenefit) {
        return parseFloat(value - (value / 100) * this.level.discount)
      }

      return value
    },
    resolveDetails () {
      this.details = {
        name: this.socialNetwork_selected.name,
        image: this.socialNetwork_selected.image,
        price: this.socialNetwork_selected.value,
        provider: 'ContasInfinity',
        extraInfo1: 'SMS',
        extraInfo2: this.option_selected.name,
        api: {
          url: '/user/services/sms/purchase',
          params: {
            option: this.option_selected.name,
            social_network: this.socialNetwork_selected.name
          }
        }
      }
    },
    async purchase () {
      this.loading = true
      const api = this.details.api
      await this.$http.post(api.url, api.params).then(res => {
        if (res.data.message === 'successfully created.' && res.status === 201) this.success()
        if (res.data.message === 'no balance.') this.noBalance()
        if (res.data.message === 'No numbers available.') this.noNumbers()
        if (res.data.message === 'Error while getting number.') this.errorGetNumber()
      }).finally(() => {
        this.loading = false
      })
    },
    success () {
      this.$swal(SwalMessages.sms_success)
        .then(buttons => (buttons.isConfirmed
          ? this.$router.push({ name: 'Números Ativos' })
          : null))
    },
    noBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    },
    noNumbers () {
      this.$swal(SwalMessages.swal_sms_no_numbers)
    },
    errorGetNumber () {
      this.$swal(SwalMessages.swal_simple_error)
    }
  },
  beforeDestroy () {
    this.setSocialNetwork({})
  }
}
