<template>
  <b-row>
    <b-col
      offset-sm-2
      sm="10"
      md="12"
      offset-lg="2"
      lg="10"
      class="mx-auto"
    >
      <b-row>
        <b-col
          v-for="(item, index) in service_selected.options"
          :key="index"
          cols="12"
          md="4"
          sm="12"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-center"
              >
                <img
                  class="rounded-lg"
                  :src="`${$api_host}/storage/assets/${item.image}`"
                  width="50px"
                >
              </b-col>

              <b-col
                cols="12"
                class="d-flex justify-content-center"
              >
                <span class="font-weight-bolder mt-1 text-center">
                  {{ item.name }}
                </span>
              </b-col>

              <b-col
                v-if="[3, 4].includes(item.type)"
                class="mt-1 d-flex justify-content-center"
                cols="12"
              >
                <b-badge :variant="item.stock > 0 ? 'primary' : 'danger'">
                  <feather-icon
                    icon="ArchiveIcon"
                    class="mr-25"
                  />
                  <span>Estoque: {{ item.stock }}</span>
                </b-badge>
              </b-col>

              <b-col
                v-if="[3, 4].includes(item.type)"
                class="mt-1 d-flex justify-content-center"
                cols="12"
              >
                <b-badge variant="primary">
                  <span>{{ item.value | currency }} a unidade</span>
                </b-badge>
              </b-col>

              <b-col cols="12">
                <b-button
                  :disabled="!checkDeactivated(item)"
                  block
                  class="mt-2"
                  variant="relief-primary"
                  @click.stop="select(item)"
                >
                  Selecionar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BButton, BBadge
} from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BButton,
    BBadge
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    }
  },
  computed: {
    ...mapState('buyServices', ['services', 'service_selected']),
    ...mapGetters('buyServices', ['services', 'isSMS'])
  },
  methods: {
    ...mapActions('buyServices', ['setOption']),
    select (payload) {
      this.setOption(payload)
      this.$emit('next-step')
    },
    checkDeactivated (item) {
      return this.isSMS
        ? !item.deactivated
        : !item.deactivated
            || (!item.deactivated && item.type !== 3)
            || (!item.deactivated && item.type === 3 && item.stock > 0)
    }
  }
}
</script>
